<template>
  <div class="product_page">
    <div class="middle_img_Box">
      <img
        src="../../../assets/productImg/product_img.jpg"
        title="尚云科技有限公司"
      />
    </div>
    <!-- 中间标题 -->

    <div class="container">
      <!-- 产品案例 -->
      <header>
        <h3>产品案例</h3>
        <p>Product Case</p>
      </header>
      <CaseShow :caseData="productFirstData" v-loading='loading'/>
      <header>
        <h3>项目案例</h3>
        <p>Project Cases</p>
      </header>
      <!-- 项目案例 -->
      <el-row :gutter="20" v-loading='loading'>
        <el-col
          :xs="24"
          :sm="24"
          :md="6"
          v-for="(item, index) in productSecondData"
          :key="index"
          class="project_box"
          @click='goProductDetail(item.id)'
          ><div class="img_box">
            <img
              :src="`/api/profile/product/${item.route}`"
              alt=""
            />
          </div>
          <div class="title_box">
            <h3 class="case_title">{{ item.name }}</h3>
            <p class="descri">{{ item.descri }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getProductList } from "../../../api/index";
import CaseShow from "../../../components/CaseShow";
export default {
  name: "ProductPage",
  data() {
    return {
      productFirstData: null, //两个大图
      productSecondData: null,
      // loading状态
      loading:true,
    };
  },
  components: {
    CaseShow,
  },
  created() {
    //   请求板块数据
    getProductList().then((res) => {
      this.loading=false
      this.productFirstData = [];
      this.productSecondData = [];
      res.rows.forEach((item) => {
        if ( item.route && item.descri !== "" &&item.productSort == 0) {
          this.productFirstData.push(item);
        } else if(item.route && item.descri !== "" && item.productSort !== 0){
          this.productSecondData.push(item);
        }
      });
    });
  },
  methods:{
    goProductDetail(data){
      this.$router.push({path:'/productDetil',query:{id:data}})
    }
  }
};
</script>
<style scoped lang="less">
.middle_img_Box {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
  }
}

header {
  margin: 30px 0;
  h3,
  p {
    text-align: center;
    font-size: 22px;
    color: #333;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }
  p {
    margin-top: 10px;
  }
}
.img_box {
  overflow: hidden;
  img {
    width: 100%;
    height: 176px;
    transition: 0.5s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.descri {
  font-size: 13px;
  line-height: 20px;
  padding: 0 10px;
  color: #666;
  letter-spacing: 1px;
  text-align: left;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 10px 0;
}

.case_title {
  padding: 10px 10px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
}
.project_box {
  cursor: pointer;
  margin-bottom: 20px;
  &:hover .title_box {
    box-shadow: 0 0 5px #ccc;
  }
}
.title_box {
  padding-bottom: 20px;
}
</style>

